html,
body {
  margin: 0px !important;
  transition: 0.5s all ease-in-out;
}
.shadowAlternate {
  box-shadow: 0 0 5px 2px #efefef;
}

.gilroy-col {
  color: #707eae !important;
}

.form-check-input:checked {
  background-color: #0dca3b !important;
  border-color: #0dca3b !important;
}

input[type="radio"] {
  width: 20px !important;
  height: 20px !important;
}

.light-bg-col {
  background-color: #ebf0fe !important;
}

.light-bg-col2 {
  background-color: #e9edf5 !important;
}

.fs-12 {
  font-size: 12px !important;
}
.toast-container {
  z-index: 1000000000000;
}
.customPhoneFlag {
  outline: none;
}

::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #ebf0fe;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0 108 223);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f0ede8;
}

.form-control:focus {
  box-shadow: unset !important;
  border: none !important;
}

.form-control {
  border: none !important;
}

.long-text {
  max-height: calc(1em * 43);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-col-pri {
  color: #0082ea;
}

.cursor {
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.modal-backdrop.fade {
  backdrop-filter: blur(10px) !important;
  background-color: #6c6c6ce8 !important;
  opacity: 1 !important;
}

.sideBarOpenBody {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #0000008f;
    backdrop-filter: blur(5px);
  }
}

.hoverOption {
  border: 1px solid transparent;
  transition: 0.5s all ease-in-out;
  cursor: pointer;

  &:hover {
    border: 1px solid #0d6efd;
  }
}

.nav-link {
  transition: 0.5s all ease-in-out;
}

.w40Vw {
  width: 40vw;
}

.paginationWrap {
  position: absolute;
  bottom: 0;
  margin: auto;
  box-shadow: 0px 0px 50px 50px white;
  padding: 1rem 0;
  z-index: 100;
  background-color: white;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination span {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 4px;
  cursor: pointer;
}

.pagination span.active {
  border: 6px solid #2f64ed;
  width: 15px;
  height: 15px;
}

.pagination span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #b7bfd7;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.pagination span.active:before {
  content: unset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::ng-deep .modal-backdrop {
  z-index: 950;
}
.lazyImage {
  transition: opacity 1s;
  opacity: 0;
}
.ng-lazyloaded {
  opacity: 1;
}
.cstmEllipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 786px) {
  .w40Vw {
    width: 90vw;
  }

  .paginationWrap {
    position: static;
    margin: auto;
    box-shadow: 0px 0px 50px 50px white;
    padding: 1rem 0;
    z-index: 100;
    width: 100%;
  }

  .agreeSmall {
    font-size: 12px;
  }
}


:root {
  --zsiq-seasonal-image-height: 50px !important;
}