@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-b";
  src: url("../fonts/Gilroy-Bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-extraB";
  src: url("../fonts/Gilroy-ExtraBold.woff") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-sb";
  src: url("../fonts/Gilroy-SemiBold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-l";
  src: url("../fonts/Gilroy-Light.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-m";
  src: url("../fonts/Gilroy-Medium.woff2") format("woff2");
  font-display: swap;
}

.gilroyEB {
  font-family: Gilroy-extraB;
}

.gilroy {
  font-family: "Gilroy";
}
.gilroy-l {
  font-family: "Gilroy-l";
}

.dCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gilroy-b,
h1,
h2,
h3,
h4 {
  font-family: "Gilroy-b";
  transition: 0.5s all ease-in-out;
}

.gilroy-sb,
h6,
h5,
label,
span,
p,
input,
.btn,
textarea {
  font-family: "Gilroy-sb";
  transition: 0.5s all ease-in-out;
}

.gilroy-l {
  font-family: "Gilroy-l";
  transition: 0.5s all ease-in-out;
}

.gilroy-m,
small,
strong {
  font-family: "Gilroy-m";
  transition: 0.5s all ease-in-out;
}
