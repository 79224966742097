.btnPrimary {
  font-family: "Gilroy-b";
  background-color: #006de0;
  border-radius: 0.375rem;
  color: #fff;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #006de0;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    border: 1px solid #006de0;
    background-color: #fff;
    color: #006de0;
  }

  &:disabled {
    background-color: #61a1fe;
    border: 1px solid #61a1fe;
    color: #fff;
    cursor: not-allowed;
  }
}

.btnDanger {
  font-family: "Gilroy-b";
  border-radius: 0.375rem;
  background-color: #dc3545;
  color: #fff;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #dc3545;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    border: 1px solid #dc3545;
    background-color: #fff;
    color: #dc3545;
  }

  &:disabled {
    background-color: #dc3546b2;
    border: 1px solid #dc3546b2;
    color: #fff;
    cursor: not-allowed;
  }
}

.btnDark {
  font-family: "Gilroy-b";
  background-color: #000;
  border-radius: 0.375rem;
  color: #fff;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #000;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #fff;
    color: #000;
  }

  &:disabled {
    background-color: #1d1d1d;
    border: 1px solid #1d1d1d;
    color: #fff;
    cursor: not-allowed;
  }
}

.transparentBtn {
  background: none;
  border: none;
}
.btnPurple {
  font-family: "Gilroy-b";
  background-color: #211231;
  padding: 0.375rem 0.75rem;
  border: 1px solid #211231;
  border-radius: 0.375rem;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  color: white;
  &:hover {
    background-color: #fff;
    color: #211231;
  }

  &:disabled {
    background-color: #1d1d1d;
    border: 1px solid #1d1d1d;
    color: #fff;
    cursor: not-allowed;
  }
}
.btnWhite {
  font-family: "Gilroy-b";
  background-color: white;
  padding: 0.375rem 0.75rem;
  border: 1px solid white;
  border-radius: 0.375rem;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  color: #006de0;
  &:hover {
    background-color: #006de0;
    color: white;
  }

  &:disabled {
    background-color: #1d1d1d;
    border: 1px solid #1d1d1d;
    color: #fff;
    cursor: not-allowed;
  }
}
